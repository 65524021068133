<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList" :buttonRemove="false" :filters="filters">
      <div slot="content-buttons">
        <Button _key="btnSignatureCreate" title="Documento" classIcon="fas fa-plus-circle" type="primary" size="small"
          eventName="signatureCreate" :clicked="create" />
      </div>
      <div slot="content-buttons-table-header">
        <Button _key="btnCancelSignature" :title="'Cancelar'" type="danger" size="small" :clicked="confirmCancel" />
      </div>
    </ViewTemplateWithTable>

    <Modal title="Assinaturas" :width="900" v-show="showModal('signers')">
      <SignerList />
    </Modal>
    <Modal title="Selecione o documento" :width="900" v-show="showModal('document')">
      <SendSingleSignature />
    </Modal>
    <Modal title="Tem certeza que deseja cancelar?" :width="500" v-show="showModal('cancelSignature')">
      <Alert type="warning">
        <span>
          Atenção, certifique-se que está cancelando correntamente, está operação é irreversível
        </span>
      </Alert>
      <Confirmation :isModal="false" title="Você tem certeza?" type="danger" :confirmed="cancel" />
    </Modal>
  </div>
</template>

<script>


import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";

import SendSingleSignature from '../../../components/modules/signature/send-signature/SendSingleSignature.vue'
import SignerList from '../../../components/modules/signature/signer/SignerList.vue'

import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "SignatureListView",
  components: {
    ViewTemplateWithTable,
    Modal,
    Button, SignerList, SendSingleSignature, SignerList, Alert, Confirmation
  },
  data() {
    return {
      panel: {
        module: "Assinatura Digital",
        title: "Documento",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/signature-file/get-all",
        urlDeleteAllApi: "/api/v1/signature-file/cancel",
        showChecks: false,
        headerTable: [
          {
            title: "Emissão",
            field: "registeredDate",
            type: "dateTime",
            iconSearch: false,
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "fullName",
            title: "Cliente",
            type: "text",
            iconSearch: true,
            classCssBody: "limit",
          },
          {
            field: "name",
            title: "Documento",
            type: "text",
            classCssBody: "limit",
          },
          {
            field: "totalSigners",
            title: "Assinaturas",
            type: "link",
            iconSearch: false,
            eventName: "openSigners",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "source",
            title: "Origem",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "registeredUser",
            title: "Emitido por",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "statusName",
            title: "Status",
            type: "class",
            fieldComparison: "statusName",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "text-center badge badge-warning",
                fieldComparison: "Pendente",
              },
              {
                classCss: "text-center badge badge-success",
                fieldComparison: "Assinado",
              },
              {
                classCss: "text-center badge badge-info",
                fieldComparison: "Aberto",
              },
              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Recusado",
              },
            ],
          },
        ],
      },
      filters: [
        {
          type: "options",
          title: "Status",
          field: "StatusName",
          options: [
            { text: "Aberto", value: "Aberto" },
            { text: "Assinado", value: "Assinado" },
            { text: "Pendente", value: "Pendente" },
          ],
          value: [],
        },
        {
          type: "options",
          title: "Origem",
          field: "Source",
          options: [
            { text: "Locação", value: "Locação" },
            { text: "Operacional", value: "Operacional" },
            { text: "Movimentação", value: "Movimentação" },
            { text: "Faturamento", value: "Faturamento" },
            { text: "N/I", value: "N/I" },
          ],
          value: [],
        },
      ],
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event", "listIdToString"]),
    ...mapState("generic", ["selected"]),
  },
  methods: {
    ...mapMutations("generic", ["openModal", "hideModal", "removeLoading"]),
    ...mapActions("generic", ["deleteAllApi"]),
    create() {
      this.openModal("document");
      this.removeLoading(["btnSignatureCreate"]);
    },
    confirmCancel() {
      this.removeLoading(["btnCancelSignature"]);
      this.openModal("cancelSignature");
    },
    cancel() {
      let params = {
        url: this.templateList.urlDeleteAllApi,
        ids: this.listIdToString(['teste']),
      };
      this.deleteAllApi(params).then((response) => {
        if (response.success) {
          this.hideModal("generateInvoice");
        }
        this.removeLoading(["confirm"]);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "openSigners")
          this.openModal("signers");
      },
      deep: true,
    },
  },
};
</script>
<style>
.div-tag {
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>