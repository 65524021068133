<template>
    <div>
        <div v-if="!fileName" class="div-select-document text-center">
            <FileUpload title="Carregar .PDF" classIcon="fa-solid fa-file-pdf" container="nixloc-signature" accepted=".pdf"
                allowed=".pdf" :disabled="true" urlPost="/api/v1/adm/file-upload/upload"
                urlRemove="/api/v1/adm/file-upload/delete" :nameDataBase="fileName" v-model="fileName" />
        </div>
        <div v-if="fileName">
            <SendSignature :single="true" :singleUrlDocument="fileName" :back="back" />
        </div>
    </div>
</template>

<script>

import FileUpload from "@nixweb/nixloc-ui/src/component/forms/FileUpload";
import SendSignature from '../../adm/document/SendSignature.vue'

import { mapGetters } from "vuex";

export default {
    name: "SendSingleSignature",
    components: {
        FileUpload, SendSignature
    },
    data() {
        return {
            fileName: ""
        }
    },
    computed: {
        ...mapGetters("generic", ["event"]),
    },
    methods: {
        back() {
            this.fileName = "";
        }
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "signatureSent") {
                    this.fileName = "";
                }
            },
            deep: true,
        },
    },
}


</script>
<style scoped>
.div-select-document {
    padding: 50px;
}
</style>